import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const VerticalSolid = ({
  compContent,
  index,
  align,
  bg,
  color,
  first,
  last,
}) => {

  const even = index % 2 === 0;
  const border = {
    borderColor: color,
  };

  return (
    <div
      className={`col-12 ${even ? styles.normal : styles.reverse} ${styles[`align${align}`]}${index === first ? ` ${styles.verticalSolidComponent}` : ''}`}
    >
      <div
        className={`${styles.compContent} ${styles[`border${even ? 'Right' : 'Left'}`]}${index === last ? ` ${styles.last}` : ''}`}
        style={bg !== undefined ? { ...bg, ...border } : border}
      >
        {compContent}
      </div>
      <div
        className={`${styles.empty} ${styles[`border${!even ? 'Right' : 'Left'}`]}${index === last ? ` ${styles.last}` : ''}`}
        style={border}
      />
      <div
        className={`${styles.empty2} ${styles[`border${even ? 'Right' : 'Left'}`]}${index === last ? ` ${styles.last}` : ''}`}
        style={border}
      />
    </div>
  );

};

VerticalSolid.propTypes = {
  compContent: PropTypes.arrayOf(PropTypes.element),
  index: PropTypes.number,
  align: PropTypes.string,
  bg: PropTypes.shape({}),
  color: PropTypes.string,
  first: PropTypes.number,
  last: PropTypes.number,
};

export default VerticalSolid;
