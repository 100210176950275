import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';
import axios from 'axios';

import { formColor, hexToRgb } from '../../../../helper';
import ButtonEditor from '../../ButtonEditor';
import SectionMedia from '../SectionMedia';

import SimpleCheckbox from './SimpleCheckbox';
import SimpleRadioButton from './SimpleRadioButton';
import styles from './styles.module.css';

const emailServiceURL = process.env.CONTACTFORM_LAMBDA_URL;

class ContactLayout extends React.PureComponent {
  static createSelect(data, index, id) {
    const options = [];
    data.forEach((opt, idx) => {
      const option = (
        <option
          disabled={idx === 0}
          key={`dropDownOptionGroup_${index}_input_${idx}`}
          id={`dropDownOptionGroup_${index}_input_${idx}`}
          name={`dropDownOptionGroup_${index}`}
          className={styles.inputOption}
          value={opt}
          data-index={index}
        >
          {opt}
        </option>
      );

      options.push(option);
    });

    const select = (
      <select
        id={`dropdownOptionGroup_${index}_${id}`}
        key={`select_${index}_${id}`}
        className={styles.select}
        defaultValue={data[0]}
      >
        {options}
      </select>
    );

    return select;
  }

  constructor(props) {
    super(props);

    const btnIndex = props.section.data.findIndex((elem) => elem.type === 'BUTTONS/BUTTON_SECONDARY');
    const dataIndex = props.section.data.findIndex((elem) => elem.type === 'DATA');

    let align = 'Left';
    if (props.section && props.section.styles && props.section.styles.align) ({ align } = props.section.styles);

    this.state = {
      section: props.section,
      align,
      formSent: false,
      formError: false,
      waitingResponse: false,
      checkRequiredFields: false,
      layout: props.section.styles.layout,
      alertText: props.section.data[dataIndex].checkRequiredFieldsMessage,
      btnIndex,
      dataIndex,
    };

    this.alertRef = React.createRef();

    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.createInput = this.createInput.bind(this);
    this.createTitles = this.createTitles.bind(this);
    this.createSendButton = this.createSendButton.bind(this);
    this.createAlert = this.createAlert.bind(this);
  }

  handleSubmitForm() {
    const col = hexToRgb('#cd5849', 0.3);
    const shadow = `0px 0px 16px 1px rgba(${col.r}, ${col.g}, ${col.b}, ${col.a})`;
    const activeInputs = [];
    const requestInputs = [];
    let validEmail = false;
    let error = false;
    let alertText;

    this.setState({
      checkRequiredFields: false,
    });

    // eslint-disable-next-line
    const validateEmailRegex = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/;

    // Loop through all active inputs

    this.state.section.data.map((component, index) => {
      if (
        (component.type === 'COMPONENT/PERMANENT' || component.type === 'COMPONENT') &&
        component.active &&
        component.data[1]
      ) {
        if (
          component.data[1].active &&
          (component.data[1].type === 'FORM/INPUT' ||
            component.data[1].type === 'FORM/INPUT/TEXT' ||
            component.data[1].type === 'FORM/INPUT/RADIO' ||
            component.data[1].type === 'FORM/INPUT/CHECKBOX' ||
            component.data[1].type === 'FORM/INPUT/DROPDOWN' ||
            component.data[1].type === 'FORM/INPUT/PHONENUMBER' ||
            component.data[1].type === 'FORM/INPUT/EMAIL' ||
            component.data[1].type === 'FORM/INPUT/TEXTAREA')
        ) {
          const cleanText = component.data[0].text.replace(/<\/?[^>]+(>|$)/g, '');
          // eslint-disable-next-line
          component.data[1].text = cleanText;
          // eslint-disable-next-line
          component.data[1].index = index;
          activeInputs.push(component.data[1]);
        }
      }

      return null;
    });

    activeInputs.map((input) => {
      let inputItem;
      let inputElement;
      let reqError = false;

      if (input.type === 'FORM/INPUT/RADIO') {
        inputElement = document.getElementById(`radioOptionGroup_${input.index}_${this.state.section._id}`);

        let radioValue;
        if (inputElement) {
          const radioEl = inputElement.querySelector('input:checked');
          if (radioEl) radioValue = radioEl.value;
        }

        if (radioValue) {
          inputItem = {
            label: input.text,
            value: radioValue,
            required: input.required,
          };
        } else if (input.required === true) {
          error = true;
          reqError = true;
        }
      } else if (input.type === 'FORM/INPUT/CHECKBOX') {
        inputElement = document.getElementById(`checkboxOptionGroup_${input.index}_${this.state.section._id}`);
        let checkboxValue;
        const checkedData = [];
        if (inputElement) {
          // Get nodelist of checkboxes on this section
          checkboxValue = inputElement.querySelectorAll('input');
          // Make the list into an array
          const checkList = [...checkboxValue];
          // Filter the checked one
          const checked = checkList.filter((alkio) => alkio.checked === true);
          // Push only the values into a new array

          checked.forEach((data) => {
            checkedData.push(data.value);
          });
        }

        if (checkboxValue && checkedData.length > 0) {
          inputItem = {
            label: input.text,
            value: checkedData,
            required: input.required,
          };
        } else if (checkedData.length < 1 && input.required === true) {
          error = true;
          reqError = true;
        }
      } else if (input.type === 'FORM/INPUT/DROPDOWN') {
        inputElement = document.getElementById(`dropdownOptionGroup_${input.index}_${this.state.section._id}`);
        let dropdownValue;
        if (inputElement) {
          dropdownValue = inputElement.options[inputElement.selectedIndex].value || '';
          if (inputElement.selectedIndex === 0) dropdownValue = null;
        }

        if (dropdownValue) {
          inputItem = {
            label: input.text,
            value: dropdownValue,
            required: input.required,
          };
        } else if (input.required === true) {
          error = true;
          reqError = true;
        }
      } else {
        inputElement = document.getElementById(`form_${input.index}_${this.state.section._id}`);
        inputItem = {
          label: input.text,
          value: (inputElement && inputElement.value) || '',
          required: input.required,
        };

        if (input.type === 'FORM/INPUT/EMAIL' && (input.required || (inputElement && inputElement.value !== ''))) {
          validEmail = validateEmailRegex.test(inputElement.value);

          if (!validEmail) {
            error = true;
            reqError = true;
            alertText = this.state.section.data[this.state.dataIndex].checkEmailMessage;
          }
        } else if (input.required && inputElement && inputElement.value === '') {
          error = true;
          reqError = true;
        }
      }

      if (inputItem) {
        switch (input.type) {
          case 'FORM/INPUT/EMAIL':
            inputItem.category = 'email';
            break;
          case 'FORM/INPUT/CHECKBOX':
            inputItem.category = 'checkbox';
            break;
          case 'FORM/INPUT/RADIO':
            inputItem.category = 'radio';
            break;
          case 'FORM/INPUT/DROPDOWN':
            inputItem.category = 'dropdown';
            break;
          case 'FORM/INPUT/TEXTAREA':
            inputItem.category = 'textarea';
            break;
          case 'FORM/INPUT/PHONENUMBER':
            inputItem.category = 'phone';
            break;
          case 'FORM/INPUT/TEXT': {
            if (input.category === 'subject') inputItem.category = 'subject';
            else inputItem.category = 'text';
            break;
          }
          default:
            inputItem.category = 'text';
        }

        requestInputs.push(inputItem);
      }

      if (!reqError && inputElement) inputElement.style.boxShadow = 'none';
      else if (inputElement) inputElement.style.boxShadow = shadow;

      return null;
    });

    const { receiverEmail } = this.state.section.data[this.state.dataIndex];
    const validReceiverEmail = validateEmailRegex.test(receiverEmail);

    if (!validReceiverEmail) {
      error = true;
      alertText = this.state.section.data[this.state.dataIndex].checkReceiverEmailMessage;
    }

    if (error) {
      const alertElement = this.alertRef.current;
      if (alertElement) alertElement.scrollIntoView();

      const alert = alertText || this.state.section.data[this.state.dataIndex].checkRequiredFieldsMessage;

      this.setState({
        checkRequiredFields: true,
        alertText: alert,
      });
    } else if (typeof window.grecaptcha !== 'undefined') {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, { action: 'submitContactForm' }).then((token) => {
          const formSection = document.getElementById(`#${this.state.section._id}`);
          const { subjectTimeStamp } = this.state.section.data[this.state.dataIndex] || false;

          this.setState({
            checkRequiredFields: false,
          });

          const currentUrl = window.location.href;
          let sentFromText = '';

          if (this.props.language === 'en') sentFromText = `This mail is sent via contact form on\n${currentUrl}`;
          else sentFromText = `Viesti lähetetty osoitteesta\n${currentUrl}`;

          /* eslint-disable */

            const requestBody = {
              "inputs": requestInputs,
              "g-recaptcha-response": token,
              "receiverEmail": receiverEmail,
              "g-recaptcha-version": 'V3',
              "subjectTimeStamp": subjectTimeStamp,
              "sentFromDomain": sentFromText,
              "defaultSubject": this.state.section.data[this.state.dataIndex].defaultSubject,
            };


            /* eslint-enable */

          const formButton = document.getElementById(`formButton_${this.state.section._id}`);
          const requestBodyString = JSON.stringify(requestBody);
          const emailInstance = axios.create({
            withCredentials: false,
            headers: { 'Content-Type': 'application/json' },
          });

          this.setState({
            waitingResponse: true,
          });

          emailInstance
            .post(emailServiceURL, JSON.stringify(requestBodyString))
            .then((res) => {
              if (res.data.statusCode === 200) {
                if (formButton) formButton.style.display = 'none';

                const form = document.getElementById(`form_${this.state.section._id}`);
                if (form) form.style.display = 'none';

                this.setState(
                  {
                    formSent: true,
                    formError: false,
                    waitingResponse: false,
                  },
                  () => {
                    if (formSection)
                      formSection.scrollIntoView({ block: 'start', inline: 'nearest', behaviour: 'smooth' });
                  },
                );
              } else {
                if (formButton) formButton.style.display = 'none';

                const formResponse = document.getElementById(`formResponse_${this.state.section._id}`);
                formResponse.innerHTML = this.state.section.data[this.state.dataIndex].errorMessage;

                this.setState(
                  {
                    formSent: true,
                    formError: true,
                    waitingResponse: false,
                  },
                  () => {
                    if (formSection)
                      formSection.scrollIntoView({ block: 'start', inline: 'nearest', behaviour: 'smooth' });
                  },
                );
              }
            })
            .catch((err) => {
              console.error(err);
              this.setState({
                formSent: true,
                formError: true,
                waitingResponse: false,
              });
            });
        });
      });
    } else {
      console.log('Error, reCaptcha is not loaded.');

      this.setState({
        formSent: false,
        formError: true,
        waitingResponse: false,
        alertText: this.state.section.data[this.state.dataIndex].checkRecaptchaMessage,
      });
    }
  }

  createInput(data, index, itemIndex) {
    let type = 'text';
    if (data.type === 'FORM/INPUT/PHONENUMBER') type = 'tel';
    else if (data.type === 'FORM/INPUT/EMAIL') type = 'email';

    const input = (
      <input
        id={`form_${itemIndex}_${this.state.section._id}`}
        key={`form_${itemIndex}_${this.state.section._id}`}
        type={type}
        className={styles[`contactFormInput${this.props.themeData.typography.default.fontSize}`]}
        tabIndex={index + 1}
        placeholder={data.placeholder}
        required={data.required}
      />
    );

    return input;
  }

  createElement(item, index, formStyle) {
    let col = '';

    if (this.state.layout === 'design2') {
      if (
        item.item.data[1] &&
        (item.item.data[1].type === 'FORM/INPUT/TEXTAREA' || item.item.data[1].type === 'IMAGES/IMAGE')
      )
        col = 'col-12';
      else col = 'col-6';
    }

    return (
      <div
        key={`${this.state.section._id}_layout_${index}`}
        className={`${col} ${styles.contactFormLabel} ${styles[`align${item.item.align}`]}`}
        style={formStyle}
      >
        <label htmlFor={`form_${item.item.data[item.item.data.length - 1].category}_${this.state.section._id}`}>
          {item.item.data.map((cntnt) => {
            let result;
            if (cntnt.active) {
              if (
                cntnt.type === 'PARAGRAPH/PARAGRAPH' ||
                cntnt.type === 'FORM/SUBTITLE' ||
                cntnt.type === 'FORM/PARAGRAPH'
              ) {
                let classNames;
                let content;
                let exception = false;

                if (cntnt.type === 'PARAGRAPH/PARAGRAPH') {
                  classNames = `${styles.contactFormText} ${styles.inputField}`;
                  content = `<span>${cntnt.text}${
                    item.item.data[1] && item.item.data[1].required ? '<span> *</span>' : ''
                  }</span>`;
                  exception = true;
                } else if (cntnt.type === 'FORM/SUBTITLE') {
                  classNames = styles.contactFormSubtitle;
                  content = `<h5>${cntnt.text}</h5>`;
                } else {
                  classNames = styles.contactFormParagraph;
                  content = `<span>${cntnt.text}</span>`;
                }

                result = (
                  <div key={`text_${index}_${this.state.section._id}`} className={classNames}>
                    <div className={exception ? styles.inputField : undefined}>{HTMLParser(content)}</div>
                  </div>
                );
              } else if (
                cntnt.type === 'FORM/INPUT' ||
                cntnt.type === 'FORM/INPUT/TEXT' ||
                cntnt.type === 'FORM/INPUT/EMAIL' ||
                cntnt.type === 'FORM/INPUT/PHONENUMBER'
              ) {
                result = this.createInput(cntnt, index, item.index);
              } else if (cntnt.type === 'FORM/INPUT/TEXTAREA') {
                result = (
                  <textarea
                    id={`form_${item.index}_${this.state.section._id}`}
                    key={`form_${item.index}_${this.state.section._id}`}
                    name="content"
                    required={cntnt.required}
                    className={styles[`contactFormTextarea${this.props.themeData.typography.default.fontSize}`]}
                    tabIndex={index + 1}
                    placeholder={cntnt.placeholder}
                  />
                );
              } else if (cntnt.type === 'IMAGES/IMAGE') {
                result = (
                  <SectionMedia
                    key={`image_${index}_${this.state.section._id}`}
                    mediaType={cntnt.content.icon ? 'ICON' : 'IMAGE'}
                    wrapperStyleName="contactImageWrapper"
                    imageStyleName="contactImage"
                    images={this.props.images}
                    src={`${process.env.IMAGES_CDN}/${cntnt.content.src}`}
                    alt={cntnt.content.alt}
                    data={cntnt.content}
                    colors={this.props.themeData.colors}
                  />
                );
              } else if (cntnt.type === 'FORM/INPUT/RADIO') {
                result = (
                  <div
                    id={`radioOptionGroup_${item.index}_${this.state.section._id}`}
                    key={`radioOptionGroup_${item.index}_${this.state.section._id}`}
                    className={cntnt.flex === 'column' ? styles.inputFlexColumn : styles.inputFlexRow}
                  >
                    {cntnt.data.map((opt, idx) => (
                      <SimpleRadioButton
                        key={`radioOptionGroup_${item.index}_input_${idx}`}
                        value={opt}
                        index={item.index}
                        idx={idx}
                        sectionId={this.state.section._id}
                        flex={cntnt.flex}
                      />
                    ))}
                  </div>
                );
              } else if (cntnt.type === 'FORM/INPUT/CHECKBOX') {
                result = (
                  <div
                    id={`checkboxOptionGroup_${item.index}_${this.state.section._id}`}
                    key={`checkboxOptionGroup_${item.index}_${this.state.section._id}`}
                    className={cntnt.flex === 'column' ? styles.inputFlexColumn : styles.inputFlexRow}
                  >
                    {cntnt.data.map((opt, idx) => (
                      <SimpleCheckbox
                        key={`checkboxOptionGroup_${item.index}_input_${idx}`}
                        value={opt}
                        index={item.index}
                        idx={idx}
                        sectionId={this.state.section._id}
                        flex={cntnt.flex}
                      />
                    ))}
                  </div>
                );
              } else if (cntnt.type === 'FORM/INPUT/DROPDOWN') {
                result = ContactLayout.createSelect(cntnt.data, item.index, this.state.section._id);
              }
            }

            return result;
          })}
        </label>
      </div>
    );
  }

  createTitles(alertStyle) {
    const titles = [];
    this.state.section.data.forEach((cntnt, i) => {
      if (cntnt.active && (cntnt.type === 'HEADINGS/HEADING-TWO' || cntnt.type === 'PARAGRAPH/PARAGRAPH')) {
        let classNames = styles.contactFormHeader;
        let content = `<h2>${cntnt.text}</h2>`;

        if (cntnt.type === 'PARAGRAPH/PARAGRAPH') {
          classNames = styles.contactFormParagraph;
          content = `<span>${cntnt.text}</span>`;
        }

        const title = (
          <div key={`${this.state.section._id}_Title_${i}`} className={classNames}>
            {HTMLParser(content)}
          </div>
        );

        titles.push(title);
      }
    });

    const title = (
      <div
        key={`${this.state.section._id}_Titles`}
        style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}
      >
        {titles}
        {(this.state.layout === 'design2' || this.state.layout === 'design3') && <>{this.createAlert(alertStyle)}</>}
      </div>
    );

    return title;
  }

  createSendButton(responseStyle) {
    let button;
    if (this.state.btnIndex !== undefined && this.state.btnIndex > -1) {
      button = (
        <React.Fragment key={`formButton_${this.state.section._id}`}>
          {this.state.section.data[this.state.btnIndex].active && !this.state.waitingResponse && !this.state.formSent && (
            <div id={`formButton_${this.state.section._id}`} className={styles.contactBtnWrapper}>
              <div onClick={this.handleSubmitForm}>
                <ButtonEditor
                  buttons={this.props.buttons}
                  type="button"
                  button="Secondary"
                  data={this.state.section.data[this.state.btnIndex].content}
                  themeData={this.props.themeData}
                />
              </div>
            </div>
          )}
          {this.state.waitingResponse && (
            <div style={this.state.layout === 'design2' ? { width: '100%' } : undefined}>
              <p className={styles.sending}>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </p>
            </div>
          )}
          <p id={`formResponse_${this.state.section._id}`} style={responseStyle}>
            {this.state.formSent && !this.state.waitingResponse && !this.state.formError && (
              <>{this.state.section.data[this.state.dataIndex].successMessage}</>
            )}
          </p>
        </React.Fragment>
      );
    }

    return button;
  }

  createAlert(alertStyle) {
    const alert = (
      <div
        key={`check_fields_${this.state.section._id}`}
        id={`check_fields_${this.state.section._id}`}
        style={alertStyle}
      >
        <span>{this.state.alertText}</span>
      </div>
    );

    return alert;
  }

  createLayout(activeComponents) {
    let responseFontSize = '1rem';
    if (this.props.themeData.typography.default.fontSize === 'Small') responseFontSize = '0.85rem';
    else if (this.props.themeData.typography.default.fontSize === 'Large') responseFontSize = '1.25rem';

    let alertStyle = {
      display: 'none',
    };
    let responseStyle = {
      display: 'none',
    };

    if (this.state.checkRequiredFields) {
      alertStyle = {
        backgroundColor: '#cd5849',
        borderRadius: '4px',
        fontSize: responseFontSize,
        padding: '16px',
        width: '100%',
        height: '85px',
        color: 'white',
        display: 'flex',
        marginTop: '26px',
        marginBottom: '23px',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
      };
    }

    if (this.state.formSent) {
      responseStyle = {
        backgroundColor: this.state.formError ? '#cd5849' : 'forestgreen',
        borderRadius: '4px',
        fontSize: responseFontSize,
        padding: '30px',
        color: 'white',
        display: 'inline-block',
        fontWeight: 'bold',
        margin: !this.state.formError ? '20px 0' : undefined,
      };
    }

    const col1 = [];
    const col2 = [];

    const paddingLeft = this.state.layout === 'design2' ? { paddingLeft: '15px' } : {};

    const reqFields = (
      <div
        key={`required_fields_${this.state.section._id}`}
        id={`required_fields_${this.state.section._id}`}
        className={styles.contactRequiredFields}
        style={paddingLeft}
      >
        <span className={styles.alignLeft}>* {this.state.section.data[this.state.dataIndex].requiredField}</span>
      </div>
    );

    activeComponents.forEach((item, index) => {
      let style;
      if (item.item.styles.backgroundColor.active) {
        let { backgroundColor } = item.item.styles;
        if (item.item.styles.backgroundColor.solid === '' && item.item.styles.backgroundColor.gradient.from === '')
          backgroundColor = { solid: '#ffffff' };

        style = formColor(
          backgroundColor,
          false,
          item.item.styles.backgroundColor.opacity,
          undefined,
          this.props.themeData.colors,
        );
      }

      const input = this.createElement(item, index, style);

      if (this.state.layout !== 'design3') col1.push(input);
      else if (this.state.layout === 'design3') {
        let temp = input;
        if (index === 0) {
          temp = (
            <div key={`design3_element_${index}`} style={{ display: 'block', paddingTop: '64px' }}>
              {temp}
            </div>
          );
        }

        col2.push(temp);
      }
    });

    const formRow = this.state.layout !== 'left' ? 'row' : '';

    const captcha = (
      <p style={{ textAlign: 'center' }}>
        This site is protected by reCAPTCHA and the Google{' '}
        <a href="https://policies.google.com/privacy" draggable="false">
          Privacy Policy
        </a>{' '}
        and{' '}
        <a href="https://policies.google.com/terms" draggable="false">
          Terms of Service
        </a>{' '}
        apply.
      </p>
    );

    const layout = (
      <>
        {this.state.layout !== 'design3' && this.createTitles(alertStyle)}
        {this.state.layout === 'left' && this.createAlert(alertStyle)}
        {this.state.formSent &&
          !this.state.formError &&
          !this.state.waitingResponse &&
          this.createSendButton(responseStyle)}
        <form
          id={`form_${this.state.section._id}`}
          className={`${styles.contactForm} ${formRow}`}
          style={this.state.layout === 'design2' ? { justifyContent: 'flex-start' } : {}}
        >
          {this.state.layout !== 'design3' && (
            <>
              {col1}
              {col1.length > 0 && reqFields}
              {this.createSendButton(responseStyle)}
            </>
          )}
          {this.state.layout === 'design3' && (
            <>
              <div className="col-12 col-md-6">{this.createTitles(alertStyle)}</div>
              <div className="col-12 col-md-6">
                {col2}
                {col2.length > 0 && reqFields}
                {this.createSendButton(responseStyle)}
                {captcha}
              </div>
            </>
          )}
        </form>
        {this.state.layout !== 'design3' && captcha}
      </>
    );

    return layout;
  }

  render() {
    const activeComponents = [];

    this.state.section.data.map((item, index) => {
      if ((item.type === 'COMPONENT/PERMANENT' || item.type === 'COMPONENT') && item.active) {
        activeComponents.push({ item, index });
      }

      return null;
    });

    const alignStyle = `align${this.state.align}`;
    const extraPadding = this.state.layout === 'left' ? { paddingLeft: '30px', paddingRight: '30px' } : {};
    const wrapperColumns = this.state.layout === 'design3' ? 'col-10' : 'col-12 col-md-8';

    return (
      <div className="container">
        <div className={`row ${styles[alignStyle]}`}>
          <div
            className={`${wrapperColumns} ${styles.contactFormWrapper}`}
            style={{ ...extraPadding, ...this.props.boxStyle }}
          >
            {this.createLayout(activeComponents)}
          </div>
        </div>
      </div>
    );
  }
}

ContactLayout.propTypes = {
  align: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape({})),
  section: PropTypes.shape({
    _id: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        checkRequiredFieldsMessage: PropTypes.string,
      }),
    ),
  }),
  themeData: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.string),
    typography: PropTypes.shape({
      default: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      heading: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      button: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
    }),
  }),
};

export default ContactLayout;
