import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const BackToTopButton = ({ colour = 'dark' }) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const element = window;
    const handler = () => setVisible(window.scrollY > window.innerHeight);
    handler();
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(handler, {
        root: element === window ? null : element,
        threshold: 0,
      });
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
    element.addEventListener('scroll', handler);
    return () => element.removeEventListener('scroll', handler);
  }, []);

  const handleClick = useCallback(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);

  return (
    <>
      <div
        ref={ref}
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh', visibility: 'hidden' }}
      />
      <button
        type="button"
        className={`${styles.btn} ${colour} ${visible ? 'visible' : ''}`}
        onClick={handleClick}
      >
        <i className="entypo icon-up-open" aria-hidden="true" />
      </button>
    </>
  );
};

BackToTopButton.propTypes = {
  colour: PropTypes.string,
};

export default BackToTopButton;
