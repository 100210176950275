import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { constructLink, formColor } from '../../../../helper';
import Image from '../../../Image';

import styles from './styles.module.css';

class SectionMedia extends React.Component {
  constructor(props) {
    super(props);

    const { link, type } = constructLink(
      props.data.linkObj,
      props.pagePathList,
      props.articlePathList,
      props.filePathList,
    );
    this.state = {
      type,
      link,
    };
  }

  render() {
    const align = styles.alignCenter;
    const id = this.props && this.props.data ? this.props.data.id : undefined;

    const isLink = this.state.link !== undefined;
    const isLocal = isLink && this.state.type !== 'EXTERNAL' && this.state.type !== 'FILE';
    let previewTarget;
    if (!isLocal) previewTarget = this.state.link && this.state.link.startsWith('http') ? '_blank' : '_self';
    else if (typeof this.props.data.linkObj === 'object')
      previewTarget = this.props.data.linkObj.openLinkInNewTab ? '_blank' : '_self';
    else previewTarget = this.props.data.openLinkInNewTab ? '_blank' : '_self';

    let color;
    if (this.props.mediaType === 'ICON' && this.props.data.icon.color) {
      ({ color } = this.props.data.icon);
      if (this.props.data.icon.color.startsWith('COLOR_') && this.props.colors && this.props.colors.length === 4) {
        color = formColor({ solid: color }, false, 1, undefined, this.props.colors).backgroundColor;
      }
    }

    const mediaElement =
      this.props.mediaType === 'IMAGE' ? (
        <Image
          id={id}
          url={this.props.src}
          sizes={this.props.sizes || '100vw'}
          imageStyleName={this.props.imageStyleName}
          alt={this.props.alt}
          onClick={this.props.onImageClick}
          transform={this.props.data ? this.props.data.transform : undefined}
          className={this.props.imageClassName}
          images={this.props.images}
        />
      ) : (
        <div
          className={clsx(
            this.props.align === undefined ? align : styles[`align${this.props.align}`],
            styles.iconWrapper,
            styles[this.props.iconStyleName],
          )}
        >
          <span
            className={`entypo ${this.props.data.icon.class}`}
            style={{
              fontSize: this.props.data.icon.size,
              color,
            }}
          />
        </div>
      );

    return (
      <div
        className={clsx(
          styles.overflowHidden,
          this.props.align ? styles[`align${this.props.align}`] : align,
          styles[this.props.wrapperStyleName],
          this.props.className,
        )}
      >
        {isLink && isLocal && previewTarget !== '_blank' ? (
          <Link target={previewTarget} rel="noopener noreferrer" to={this.state.link}>
            {mediaElement}
          </Link>
        ) : isLink && (!isLocal || previewTarget === '_blank') ? (
          <a target={previewTarget} rel="noopener noreferrer" href={this.state.link} draggable="false">
            {mediaElement}
          </a>
        ) : (
          mediaElement
        )}
      </div>
    );
  }
}

SectionMedia.propTypes = {
  align: PropTypes.string,
  mediaType: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  data: PropTypes.shape({
    transform: PropTypes.shape({
      scale: PropTypes.number,
      rotation: PropTypes.number,
      offsetX: PropTypes.number,
      offsetY: PropTypes.number,
    }),
    openLinkInNewTab: PropTypes.bool,
    linkObj: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    id: PropTypes.string,
    icon: PropTypes.shape({
      size: PropTypes.string,
      color: PropTypes.string,
      class: PropTypes.string,
      alt: PropTypes.string,
    }),
  }),
  wrapperStyleName: PropTypes.string,
  imageStyleName: PropTypes.string,
  iconStyleName: PropTypes.string,
  imageClassName: PropTypes.string,
  className: PropTypes.string,
  onImageClick: PropTypes.func,
};

export default SectionMedia;
