import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { constructLink } from '../../../helper';
import CustomizableButton from '../../CustomizableButton';

import styles from './styles.module.css';

class ButtonEditor extends React.Component {
  constructor(props) {
    super(props);

    let link;
    let type;
    if (props.data) {
      ({ link, type } = constructLink(
        props.data.linkObj,
        props.pagePathList,
        props.articlePathList,
        props.filePathList,
      ));
    }

    let label;
    if (props.text !== undefined) label = props.text;
    else if (props.data.label) ({ label } = props.data);
    else label = '';

    const { data } = props;
    if (data && data.styles && data.styles.id && props.buttons)
      data.styles = props.buttons.find((b) => data.styles.id === b.id) || data.styles;

    this.state = { type, link, data, label };
  }

  render() {
    const button = (
      <CustomizableButton
        className={this.props.styleN && styles[this.props.styleN]}
        fullWidth={this.props.fullWidth === true}
        button={this.props.button}
        themeData={this.props.themeData}
        data={this.state.data}
        invert={this.props.invert}
        label={this.state.label}
        type={this.props.type || 'submit'}
      />
    );

    const isLink = this.state.link !== undefined;
    const isLocal = isLink && this.state.type !== 'EXTERNAL' && this.state.type !== 'FILE';
    let previewTarget;
    if (!isLocal) previewTarget = this.state.link && this.state.link.startsWith('http') ? '_blank' : '_self';
    else if (typeof this.state.data.linkObj === 'object')
      previewTarget = this.state.data.linkObj.openLinkInNewTab ? '_blank' : '_self';
    else previewTarget = this.state.data.tab ? '_blank' : '_self';

    let result = button;
    if (isLink && isLocal && previewTarget !== '_blank') {
      result = (
        <Link to={this.state.link} className={this.props.fullWidth === true ? `${styles.width100}` : ''}>
          {button}
        </Link>
      );
    } else if (isLink && (!isLocal || previewTarget === '_blank')) {
      result = (
        <a
          href={this.state.link}
          target={previewTarget}
          rel="noopener noreferrer"
          className={this.props.fullWidth === true ? `${styles.width100}` : ''}
          draggable="false"
        >
          {button}
        </a>
      );
    }

    return result;
  }
}

ButtonEditor.propTypes = {
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  data: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
    styles: PropTypes.shape({
      backgroundColor: PropTypes.shape({
        opacity: PropTypes.number,
        solid: PropTypes.string,
        gradient: PropTypes.shape({
          from: PropTypes.string,
          to: PropTypes.string,
        }),
      }),
      fontColor: PropTypes.shape({
        color: PropTypes.string,
        opacity: PropTypes.number,
      }),
      radius: PropTypes.number,
      shadow: PropTypes.shape({
        active: PropTypes.bool,
        color: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
        blur: PropTypes.number,
        spread: PropTypes.number,
        opacity: PropTypes.number,
      }),
      border: PropTypes.shape({
        active: PropTypes.bool,
        color: PropTypes.string,
        thickness: PropTypes.number,
        opacity: PropTypes.number,
        radius: PropTypes.number,
      }),
    }),
  }),
  button: PropTypes.string,
};

export default ButtonEditor;
